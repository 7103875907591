import PropTypes from 'prop-types';
import * as React from 'react';

import { HeroNav } from '../../hero';
import { HeroCTA } from './hero-cta';
import { HeroImage } from './hero-image';

const Hero = ({
  image = <HeroImage />,
  nav = <HeroNav />,
  cta = <HeroCTA />,
}) => (
  <header className="relative">
    <div className="absolute inset-0 z-0 flex">{image}</div>
    <div className="relative flex justify-center px-6 py-4 hero-gradient">
      <div className="flex flex-col justify-between flex-1 pb-12">
        {nav}
        <div className="flex w-full max-w-5xl mx-auto my-auto">
          <div className="max-w-lg sm:pr-8">{cta}</div>
        </div>
      </div>
    </div>
  </header>
);

Hero.propTypes = {
  cta: PropTypes.object,
  image: PropTypes.object,
  nav: PropTypes.object,
};

export { Hero };
