import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import FormRow from './form-row';
import Input from './input';
import Select from './select';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const SEOPageContactForm = ({
  className,
  formTitle = 'For Professional and Reliable Service Contact Us Today',
  formName = 'moving-quote-form',
}) => {
  const movingOut = `${formName}-moving-out`;
  const movingIn = `${formName}-moving-in`;
  const givenName = `${formName}-given-name`;
  const familyName = `${formName}-family-name`;
  const tel = `${formName}-tel`;
  const email = `${formName}-email`;
  const from = `${formName}-from`;
  const to = `${formName}-to`;
  const heard = `${formName}-heard`;

  const [state, setState] = React.useState({
    [movingOut]: '',
    [movingIn]: '',
    [givenName]: '',
    [familyName]: '',
    [tel]: '',
    [email]: '',
    [from]: '',
    [to]: '',
    [heard]: 'How did you hear about us?',
  });

  const isBrowser = typeof window !== 'undefined';

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    typeof window !== 'undefined' && window.gtag('event', 'conversion');

    const form = e.target;
    // @ts-ignore
    if (isBrowser && window.fbq) {
      // @ts-ignore
      window.fbq('track', 'Lead', { content_name: form.getAttribute('name') });
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      // eslint-disable-next-line no-alert
      .catch((error) => alert(error));
  };

  return (
    <div
      id={formName}
      className={`${className} max-w-4xl mx-auto relative w-full`}
    >
      <div className="px-4 py-4 mx-6 text-white rounded-lg shadow-md sm:px-12 bg-brand-blue">
        <div className="flex items-center justify-center px-3 py-1 leading-none text-center rounded bg-brand-blue">
          <h2 className="text-4xl font-semibold uppercase font-display">
            {formTitle}
          </h2>
        </div>
        <p className="mt-3 text-sm text-center">
          <span className="font-semibold">
            We'll follow up within 24 hours (Monday – Friday){' '}
          </span>
          <br />
          <span>Typical response: within 2 hours </span>
        </p>
        <form
          action="/furniture-removal/success/"
          autoComplete="on"
          className="w-full"
          data-netlify="true"
          method="POST"
          name={formName}
          onSubmit={handleSubmit}
        >
          <FormRow>
            <Input
              // @ts-ignore
              thinBorder
              label="First name (required)"
              id={givenName}
              name={givenName}
              required
              placeholder="First name *"
              value={state[givenName]}
              autoComplete="given-name"
              handleChange={handleChange}
            />
            <Input
              // @ts-ignore
              thinBorder
              margin
              label="Last name (required)"
              id={familyName}
              name={familyName}
              required
              placeholder="Last name *"
              value={state[familyName]}
              autoComplete="family-name"
              handleChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Input
              // @ts-ignore
              thinBorder
              label="Phone number (required)"
              id={tel}
              name={tel}
              required
              placeholder="Contact number *"
              value={state[tel]}
              autoComplete="tel"
              handleChange={handleChange}
            />
            <Input
              // @ts-ignore
              thinBorder
              margin
              label="Email address (required)"
              id={email}
              name={email}
              type="email"
              required
              placeholder="Email address *"
              value={state[email]}
              autoComplete="email"
              handleChange={handleChange}
            />
          </FormRow>

          <FormRow>
            <Input
              // @ts-ignore
              thinBorder
              label="Moving from postcode"
              id={from}
              name={from}
              placeholder="Moving from (postcode)"
              value={state[from]}
              autoComplete="postal-code"
              handleChange={handleChange}
            />
            <Input
              // @ts-ignore
              thinBorder
              margin
              label="Moving to postcode"
              id={to}
              name={to}
              placeholder="Moving to (postcode)"
              value={state[to]}
              handleChange={handleChange}
            />
          </FormRow>

          <FormRow>
            {/* @ts-ignore */}
            <Select
              thinBorder
              label="How did you hear about us? (please choose)"
              id={`${formName}-heard`}
              name={`${formName}-heard`}
              value={state[heard]}
              handleChange={handleChange}
            >
              <option value="">How did you hear about us? </option>
              <option value="Facebook">Facebook </option>
              <option value="Google">Google </option>
              <option value="Word of mouth">Word of mouth </option>
              <option value="Friend">Friend </option>
              <option value="Mailouts">Mailouts </option>
              <option value="Other">Other </option>
            </Select>
          </FormRow>

          <div className="flex flex-wrap items-center mt-4 sm:flex-nowrap sm:space-x-4">
            {/* Submit */}
            <button
              type="submit"
              className="flex-shrink-0 inline-block px-12 py-1 mt-4 mb-4 font-semibold border-2 border-transparent rounded sm:ml-auto text-brand-orange bg-brand-gray sm:mt-0"
            >
              Quote my move
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

SEOPageContactForm.propTypes = {
  className: PropTypes.string,
  formTitle: PropTypes.string,
  formName: PropTypes.string,
};

export default SEOPageContactForm;
