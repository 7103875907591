/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const HandIcon = (props) => (
  <svg
    fill="#f15c2f"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 368 368"
    {...props}
  >
    <path d="M169.32,249a7.12,7.12,0,1,0-10.07,0A7.14,7.14,0,0,0,169.32,249Z" />
    <path d="M135.61,80.05A78.29,78.29,0,1,0,213.9,1.76,78.38,78.38,0,0,0,135.61,80.05Zm142.34,0a64.06,64.06,0,1,1-64-64A64.13,64.13,0,0,1,278,80.05Z" />
    <path d="M83.79,364.13a7.13,7.13,0,0,0,10,.06l34.5-34a21.52,21.52,0,0,0,5.07-22.08l7.42-7.16a21.2,21.2,0,0,1,14.82-6h94.6a63.7,63.7,0,0,0,44.68-18.15c.49-.48-3.76,4.53,64.54-77.1a28.47,28.47,0,0,0-43.32-36.95l-42,43.16A28.62,28.62,0,0,0,252,195.34H172.67A91.68,91.68,0,0,0,137,188.22c-34.27,0-64.2,15.83-80.25,45.51a21.25,21.25,0,0,0-19,5.89l-33.85,34a7.12,7.12,0,0,0,0,10ZM137,202.45A77.8,77.8,0,0,1,168.36,209a7.09,7.09,0,0,0,2.86.6H252A14.24,14.24,0,1,1,252,238H194a7.12,7.12,0,1,0,0,14.23H252A28.5,28.5,0,0,0,280.5,223.8a28.81,28.81,0,0,0-.25-3.72c40.62-41.76,46.35-47.62,46.6-47.91a14.23,14.23,0,1,1,21.73,18.39l-63.83,76.31a49.59,49.59,0,0,1-34.5,13.87h-94.6a35.35,35.35,0,0,0-24.71,10l-6,5.83L69.14,240.8C82.17,216.36,106.62,202.45,137,202.45ZM47.82,249.68a7.09,7.09,0,0,1,8.81-1c1.24.75-2.3-2.47,61.65,61.39a7.13,7.13,0,0,1,0,10l-29.41,29L19,278.61Z" />
    <path d="M205.36,37.35v8.34a21.34,21.34,0,0,0,7.11,41.47,7.12,7.12,0,0,1,0,14.24c-3,0-6.32-1.91-9.24-5.39a7.12,7.12,0,1,0-10.89,9.16,29.46,29.46,0,0,0,13,9.27v8.31a7.12,7.12,0,0,0,14.23,0v-8.34a21.35,21.35,0,0,0-7.12-41.48,7.12,7.12,0,0,1,0-14.23c2.52,0,5.19,1.28,7.7,3.72a7.12,7.12,0,0,0,9.9-10.23,27.45,27.45,0,0,0-10.48-6.55V37.35a7.12,7.12,0,1,0-14.23,0Z" />
  </svg>
);

export default HandIcon;
