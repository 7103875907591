import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import Hand from '../icons/hand';

const Ballina = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted } = data.site.siteMetadata;

  return (
    <article
      className={`max-w-6xl px-6 pt-12 pb-16 mx-auto mt-20 ${'text-white bg-brand-blue'}`}
    >
      <div className="w-24 h-1 mx-auto bg-brand-orange" />
      <h2 className="mt-8 text-5xl font-semibold leading-none text-center uppercase font-display">
        <span className="text-brand-orange">
          Purpose-built storage containers
        </span>
        <br />
        from Frontline Removals
      </h2>
      <div className="w-full mt-12">
        <div className="flex justify-center w-full mt-6 text-center md:mt-0">
          <div className="max-w-4xl rich-text">
            Wondering where to store your belongings, you don't want to take
            with you to your new place? Frontline Removals offers the best
            storage solution for you. We offer secure and affordable short-term
            and long-term storage facilities for your personal effects.
            <br />
            <br />
            Our purpose-built storage containers are suitable for any business
            equipment and furniture to protect your goods from moisture,
            insects, theft and other unwanted elements. We have an excellent
            24-hour digital surveillance system and round-the-clock patrol teams
            to ensure that your belongings are safe inside our storage facility.
            <br />
            <div className="flex justify-center mt-12 -mb-6">
              <Hand className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h2>Our guarantee</h2>
            <br />
            We promise to deliver the best attention possible to provide the
            best removalist service in Ballina with fair and competitive pricing
            <br />
            <br />
            Even with just short notice or if you’re in a hurry to move, we
            still commit to providing you with a reliable, comprehensive and
            seamless furniture removals service at all times.
            <br />
            <br />
            Our professional team has more than{' '}
            {new Date().getFullYear() - 1995} years of solid experience in
            packing, handling and shipping furniture and other personal effects.
            This ensures that you are dealing with a professional removalist
            company that you can trust.
            <br />
            <br />
            Want to know more about our professional moving and storage in
            Ballina?
            <br />
            <br />
            Call us on <a href={`tel:${phone}`}>{phoneFormatted}</a> and speak
            to one of our friendly representatives who can take you through our
            comprehensive removalist services in Ballina.
          </div>
        </div>
      </div>
    </article>
  );
};

export { Ballina };
