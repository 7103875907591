import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import Hand from '../icons/hand';
import House from '../icons/house';

const Grafton = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted } = data.site.siteMetadata;

  return (
    <article
      className={`max-w-6xl px-6 pt-12 pb-16 mx-auto mt-20 ${'text-white bg-brand-blue'}`}
    >
      <div className="w-24 h-1 mx-auto bg-brand-orange" />
      <h2 className="mt-8 text-5xl font-semibold leading-none text-center uppercase font-display">
        <span className="text-brand-orange">Efficient and Affordable</span>{' '}
        Removalist in Grafton
      </h2>
      <div className="w-full mt-12">
        <div className="flex justify-center w-full mt-6 text-center md:mt-0">
          <div className="max-w-4xl rich-text">
            Looking into how much it would cost you a professional removalist to
            help you with relocating? We guarantee that our moving and storage
            service is affordable and a bang on the buck. With every project
            comes the promise of balancing high standards of operations that is
            offered with competitive pricing. Getting the best attention and
            service for your relocation requirement need not come at a high
            cost.
            <div className="flex justify-center mt-12 -mb-6">
              <House className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h2>Customised storage options</h2>
            <br />
            If you have things you don't want to take to your new home or office
            - and don't want to discard either, we understand. Frontline
            Removals has the perfect solution for your long and short-term
            storage needs.
            <br />
            <br />
            Our custom-built containers are designed to store everything from
            furniture to business equipment to machinery and much more. Based on
            your requirement, we can tailor a storage facility that suits you.
            <br />
            <br />
            And you need not worry about the safety at our storage facility. We
            have 24-hour digital and manual surveillance that will protect your
            valued possessions from damage and theft.
            <div className="flex justify-center mt-12 -mb-6">
              <Hand className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h2>Professional services</h2>
            <br />
            Need a stress-free removals service in Grafton within your budget?
            get a quote online, or speak to our removalist coordinator in
            Grafton on <a href={`tel:${phone}`}>{phoneFormatted}</a> today.
          </div>
        </div>
      </div>
    </article>
  );
};

export { Grafton };
