import GatsbyImage from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

const FirstSection = ({ heading, image, copy, alt }) => {
  const {
    site: {
      siteMetadata: { phone, phoneFormatted, googleMapsAddress },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
          googleMapsAddress
        }
      }
    }
  `);

  const isBrowser = typeof window !== 'undefined';

  const doFB = () => {
    const eventID = crypto.randomUUID();
    console.log(eventID);
    conversionsAPI(eventID);
    if (isBrowser && window.fbq)
      window.fbq('track', 'Contact', {}, { eventID });
  };

  async function conversionsAPI(eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventType: 'Contact', fbp, fbc, eventID }),
      });
      const data = await res.json();
      console.log('Return from netlify functions conversionsAPI =', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  return (
    <>
      <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-32">
        <div className="flex flex-col w-full md:w-1/3">
          <h2 className="text-5xl font-semibold leading-none uppercase heading font-display">
            {heading}
          </h2>
          {/* {image && (
            <div className="hidden my-auto mr-12 overflow-hidden rounded-md md:block">
              <GatsbyImage fluid={image} alt={alt} />
            </div>
          )} */}
        </div>
        <div className="w-full mt-6 md:mt-0 md:w-2/3">
          <div className="rich-text">{copy}</div>
          {alt === 'newcastle_section_1' && (
            <a
              onClick={() => doFB()}
              href={`tel:${phone}`}
              className="flex items-center justify-center mt-6 shadow whitespace-nowrap w-72 hover:shadow-md button-white"
            >
              {/* <IoIosCall /> */}
              <span className="text-sm">CALL THE TEAM</span>
            </a>
          )}
          {alt === 'newcastle_section_2' && (
            <Link
              to="/furniture-removal/#quote-form"
              // @ts-ignore
              offset="0"
              className="flex items-center justify-center mt-6 shadow whitespace-nowrap w-72 hover:shadow-md button-white"
            >
             Contact Us
            </Link>
          )}
        </div>
      </article>
      {image && (
        <div className="max-w-6xl mx-auto my-auto mt-6 -mb-12 overflow-hidden">
          <GatsbyImage fluid={image} alt={alt} />
        </div>
      )}
    </>
  );
};

FirstSection.propTypes = {
  heading: PropTypes.node.isRequired,
  image: PropTypes.object,
  copy: PropTypes.node.isRequired,
  alt: PropTypes.string,
};

export { FirstSection };
