import PropTypes from 'prop-types';
import * as React from 'react';

import { FooterImage } from './footer-image';
import { HeroCTA } from './hero-cta';

const FooterHero = ({ image = <FooterImage />, cta = <HeroCTA /> }) => (
  <header className="relative">
    {image}
    <div className="absolute inset-0 flex justify-center px-6 py-4 hero-gradient">
      <div className="flex flex-col justify-between flex-1 pb-24">
        <div className="flex w-full max-w-5xl mx-auto">{cta}</div>
      </div>
    </div>
  </header>
);

FooterHero.propTypes = {
  image: PropTypes.object,
  cta: PropTypes.object,
};

export { FooterHero };
