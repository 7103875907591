import PropTypes from 'prop-types';
import * as React from 'react';

const SecondSection = ({
  headingLine1,
  headingLine2,
  copy,
  whiteBg = false,
}) => (
  <article
    className={`max-w-6xl px-6 pt-4 pb-12 mx-auto mt-20 ${
      whiteBg ? 'text-black bg-white' : 'text-white bg-brand-blue'
    }`}
  >
    <div className="w-24 h-1 mx-auto bg-brand-orange" />
    <h2 className="mt-8 text-5xl font-semibold leading-none text-center uppercase font-display">
      {headingLine1} <span className="text-brand-orange">{headingLine2}</span>
    </h2>
    <div className="w-full mt-12">
      <div className="flex justify-center w-full mt-6 text-center md:mt-0">
        <div className="max-w-4xl rich-text">{copy}</div>
      </div>
    </div>
  </article>
);

SecondSection.propTypes = {
  copy: PropTypes.object.isRequired,
  headingLine1: PropTypes.string.isRequired,
  headingLine2: PropTypes.string.isRequired,
  whiteBg: PropTypes.bool,
};

export { SecondSection };
