/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const HangerIcon = (props) => (
  <svg
    fill="#152735"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 532 389"
    {...props}
  >
    <path d="M456.804 200.662h-22.83l-152.28-95.88c12.885-6.094 21.138-19.129 21.138-33.382 0-17.91-13.028-33.366-30.678-36.398v-19h18.46c.083.003.165.005.248.005 3.379 0 6.16-2.78 6.16-6.16 0-3.379-2.781-6.16-6.16-6.16-.083 0-.165.002-.248.005h-49.24c-3.283.132-5.912 2.87-5.912 6.155 0 3.286 2.629 6.023 5.912 6.155h18.47v24.631c0 3.374 2.776 6.15 6.15 6.15h.04c13.49.023 24.58 11.13 24.58 24.62 0 13.507-11.114 24.62-24.62 24.62s-24.62-11.114-24.62-24.62c.003-.083.005-.165.005-.248 0-3.379-2.781-6.16-6.16-6.16-3.38 0-6.16 2.781-6.16 6.16 0 .084.002.165.005.249.02 14.275 8.316 27.314 21.24 33.38l-152.27 95.88h-22.85c-3.374 0-6.15 2.775-6.15 6.15v172.34c0 3.378 2.78 6.16 6.16 6.16h381.61c3.379 0 6.16-2.781 6.16-6.16v-172.34c0-3.374-2.776-6.15-6.15-6.15h-.01zm-190.77-91.21l144.86 91.21h-289.75l144.89-91.21zm184.62 263.55H81.344v-160h369.31v160z" />
    <path d="M266.034 219.123c-3.374 0-6.15 2.775-6.15 6.15v135.42c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.38-2.781-6.16-6.16-6.16zm-30.81 0c-3.38 0-6.16 2.78-6.16 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.42c0-3.375-2.776-6.15-6.15-6.15zm-30.78 0c-3.374 0-6.15 2.775-6.15 6.15v135.42c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.38-2.781-6.16-6.16-6.16zm-30.77 0c-3.38 0-6.16 2.78-6.16 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.42c0-3.375-2.776-6.15-6.15-6.15zm-30.78 0c-3.374 0-6.15 2.775-6.15 6.15v135.42c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.38-2.781-6.16-6.16-6.16zm-30.77 0c-3.38 0-6.16 2.78-6.16 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.375-2.775-6.155-6.15-6.16zm184.65 0c-3.38 0-6.16 2.78-6.16 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.42c0-3.375-2.776-6.15-6.15-6.15zm30.77 0c-3.375.005-6.15 2.785-6.15 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.38-2.781-6.16-6.16-6.16zm30.78 0c-3.38 0-6.16 2.78-6.16 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.42c0-3.375-2.776-6.15-6.15-6.15zm30.78 0c-3.38 0-6.16 2.78-6.16 6.16v135.41c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.375-2.775-6.155-6.15-6.16zm30.77 0c-3.374 0-6.15 2.775-6.15 6.15v135.42c.132 3.283 2.869 5.911 6.155 5.911s6.023-2.628 6.155-5.911v-135.41c0-3.38-2.781-6.16-6.16-6.16z" />
  </svg>
);

export default HangerIcon;
