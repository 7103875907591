import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';

import Hand from '../icons/hand';

const SouthWestRocks = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted } = data.site.siteMetadata;

  return (
    <article
      className={`max-w-6xl px-6 pt-12 pb-16 mx-auto mt-20 ${'text-white bg-brand-blue'}`}
    >
      <div className="w-24 h-1 mx-auto bg-brand-orange" />
      <h2 className="mt-8 text-5xl font-semibold leading-none text-center uppercase font-display">
        Storage Solutions{' '}
        <span className="text-brand-orange">from Frontline Removals</span>
      </h2>
      <div className="w-full mt-12">
        <div className="flex justify-center w-full mt-6 text-center md:mt-0">
          <div className="max-w-4xl rich-text">
            You may have items that you don't want take to your new place - but
            don't want to dispose of it either. No worries!{' '}
            <Link to="https://www.frontlineremovals.com.au/">
              Frontline Removals
            </Link>{' '}
            has excellent storage solutions for you.
            <br />
            <br /> We have both long term and short-term storage facilities
            where you can store your precious possessions with us from 1 week up
            to 10 years!
            <br />
            <br /> Our track record, from 1996, includes working extensively
            with a large portfolio of clients – residential and commercial – in
            and around South West Rocks.
            <br />
            <br />
            <h3>Need storage? No problem</h3>
            <br />
            Frontline Removals specialises in storage solutions with standard
            and customised purpose built containers.
            <br />
            <br />
            Our facilities have round the clock surveillance and are well
            insulated so you do not have to be concerned about safety or
            security.
            <br />
            <br />
            This ensures the fact that your precious possessions will be free
            from any damage during the storage from mishandling, theft, pests or
            any other natural elements like fire or moisture.
            <br />
            <br />
            We only use high quality packing materials and moving equipment
            specifically meant for high value goods.
            <br />
            <br />
            <div className="flex justify-center mt-12">
              <Hand className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h3>
              Need your removalist requirement in South West Rocks to be
              covered?
            </h3>
            <br />
            For more information on quality removalist solutions in South West
            Rocks, call Frontline Removals on{' '}
            <a href={`tel:${phone}`}>{phoneFormatted}</a> today.
          </div>
        </div>
      </div>
    </article>
  );
};

export { SouthWestRocks };
