import { graphql, useStaticQuery, Link } from 'gatsby';
import * as React from 'react';

import Hand from '../icons/hand';
import Warehouse from '../icons/warehouse';
import Wallet from '../icons/wallet';
import Jail from '../icons/jail';

const Newcastle = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted } = data.site.siteMetadata;

  return (
    <>
      <article
        className={`max-w-6xl px-6 lg:px-24 pt-12 pb-16 mx-auto mt-20 ${'text-white bg-brand-blue'}`}
      >
        <div className="w-1/3 h-1 bg-brand-orange" />
        <h2 className="mt-8 text-5xl font-semibold leading-none uppercase font-display">
          <span className="text-brand-orange">Quick and Competitive</span>{' '}
          Removalists in Newcastle
        </h2>
        <div className="w-full mt-12">
          <div className="flex justify-center w-full mt-6 md:mt-0">
            <div className="max-w-6xl rich-text">
              <div className="flex flex-wrap lg:space-x-6 lg:flex-nowrap">
                <div className="w-full lg:w-1/2">
                  Moving should be easy – and our aim at Frontline is to give
                  you greater peace of mind while handling all the heavy lifting
                  of your belongings and furniture. Our highly trained
                  professionals are masters at the art of packing all your
                  possessions securely so they arrive in the same condition you
                  departed in.
                  <br />
                  <br />
                  We can move you and your entire premises across state lines or
                  across Australia. No matter how you get to your new home or
                  office, you can rely on Frontline to have your belongings
                  waiting for you when you get there.
                  <br />
                  <br />
                </div>
                <div className="w-full lg:w-1/2">
                  We use the latest innovative and sustainable materials for
                  packing, all engineered to keep your belongings safe and
                  sound.
                  <br />
                  <br />
                  You can trust Frontline to take care of all the moving – so
                  you can sit back and relax.
                  <Link
                    to="/furniture-removal/#quote-form"
                    // @ts-ignore
                    offset="0"
                    className="flex items-center justify-center w-48 py-2 mt-6 uppercase shadow whitespace-nowrap hover:shadow-md button"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              {/* <div className="flex justify-center mt-12 -mb-6">
                <House className={`w-24 ${'text-brand-orange'}`} />
              </div> */}
              <div className="flex flex-wrap mt-12 lg:space-x-6 lg:flex-nowrap lg:mt-6">
                <div className="relative w-full p-4 border rounded-md lg:w-1/2 border-brand-orange">
                  <Warehouse className="absolute w-24 -top-8 left-8 text-brand-orange" />
                  <div className="mt-12">
                    <h2>Tailored Storage Solutions</h2>
                    <br />
                    Finding proper storage for your stuff while you wait to move
                    in is often an unavoidable part of moving. The movers at
                    Frontline can advise you on the best storage option for your
                    particular requirements.
                    <br />
                    <br />
                    Frontline provides a great safeguarding option for you
                    whether you are moving to a smaller area, reducing the size
                    of your business, or going overseas. We can accommodate your
                    storage needs, whether they are short-term or long-term or
                    something in between.
                  </div>
                </div>
                <div className="relative w-full p-4 mt-12 border rounded-md lg:mt-0 lg:w-1/2 border-brand-orange">
                  <Wallet className="absolute w-24 -top-8 left-8 text-brand-orange" />
                  <div className="mt-12">
                    <h2>Affordable Storage Options</h2>
                    <br />
                    Frontline believes that storage options in Newcastle need
                    not cost the Earth. We have a versatile range of storage
                    solutions that can suit your budget; without sacrificing
                    quality or security. We can guarantee to maintain hygiene,
                    weatherproofing, and eliminate pests.
                  </div>
                </div>
              </div>
              <div className="relative p-4 mt-12 border rounded-md lg:mt-12 border-brand-orange">
                <Jail className="absolute w-24 -top-8 left-8 text-brand-orange" />
                <div className="mt-12">
                  <h2>Frontline's Portable Shipping Containers</h2>
                  <br />
                  Are you dealing with too much clutter in your home – or have
                  you realised during your move to or from Newcastle that
                  there’s stuff better off kept off-site? You can choose to use
                  Frontline’s exclusive self-storage shipping containers to keep
                  things protected within our secure storage facilities.
                  <h3>How it works:</h3>
                  <br />
                  <div className="flex flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-1/2">
                      <span className="text-2xl font-bold text-brand-orange">
                        1.
                      </span>{' '}
                      Choose a 10’, 20’, or 40’ container.
                      <br />
                      <br />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <span className="text-2xl font-bold text-brand-orange">
                        2.
                      </span>{' '}
                      We’ll deliver it to your home or office.
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="flex flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-1/2">
                      <span className="text-2xl font-bold text-brand-orange">
                        3.
                      </span>{' '}
                      Load it by yourself or have our professionals do it for
                      you.
                      <br />
                      <br />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <span className="text-2xl font-bold text-brand-orange">
                        4.
                      </span>{' '}
                      We pick up your loaded container for secure storage in our
                      facility.
                    </div>
                  </div>
                </div>
                {/* <div className="flex justify-center mt-12 -mb-6">
                <Hand className={`w-24 ${'text-brand-orange'}`} />
              </div>
              <h2>Professional moving services in Newcastle</h2>
              <br />
              Are you looking for a reliable and competitive removal service in
              Newcastle that won’t break the bank? Get your FREE quote online or
              talk to our Newcastle removalist coordinator on{' '}
              <a href={`tel:${phone}`}>{phoneFormatted}</a> today. */}
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="flex flex-wrap max-w-6xl px-16 py-24 mx-auto text-white bg-brand-orange">
        <div className="flex flex-col w-full md:w-1/3">
          <h2 className="text-5xl font-semibold leading-none uppercase font-display">
            Professional moving <br />
            services in Newcastle
          </h2>
          {/* {image && (
            <div className="hidden my-auto mr-12 overflow-hidden rounded-md md:block">
              <GatsbyImage fluid={image} alt={alt} />
            </div>
          )} */}
        </div>
        <div className="w-full mt-6 md:mt-0 md:w-2/3">
          <div className="flex flex-wrap items-center">
            <div className="md:w-2/3">
              Are you looking for a reliable and competitive removal service in
              Newcastle that won’t break the bank? Get your FREE quote online or
              talk to our Newcastle removalist coordinator on{' '}
              <a className="font-bold text-brand-blue" href={`tel:${phone}`}>
                {phoneFormatted}
              </a>{' '}
              today.
            </div>
            <div className="flex justify-center md:w-1/3">
              <Link
                to="/furniture-removal/#quote-form"
                // @ts-ignore
                offset="0"
                className="flex items-center justify-center w-48 h-16 mt-12 text-white rounded-md shadow md:mt-0 bg-brand-blue hover:shadow-md button"
              >
                GET A QUOTE
              </Link>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export { Newcastle };
