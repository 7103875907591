import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

const HeroImage = ({ fluid, objectPosition = 'top', alt }) => {
  if (!fluid) return null;
  return (
    <GatsbyImage
      fluid={fluid}
      className="flex-1"
      imgStyle={{ objectPosition }}
      alt={alt}
    />
  );
};

HeroImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  objectPosition: PropTypes.string,
  alt: PropTypes.string,
};

export { HeroImage };
