import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

const ThirdSection = ({ heading, image, copy, fullWidth = false, alt }) => (
  <>
    {image && (
      <div className="hidden max-w-6xl mx-auto my-auto mt-24 overflow-hidden md:block">
        <GatsbyImage fluid={image} alt={alt} />
      </div>
    )}
    <article
      className={`${
        image ? 'mb-12' : 'mt-24'
      } text-white bg-brand-blue flex flex-wrap ${
        fullWidth ? 'w-full' : 'max-w-6xl'
      } px-12 py-12 mx-auto`}
    >
      <div className="flex flex-col w-full mt-12 md:w-1/3">
        <h2 className="text-5xl font-semibold leading-none uppercase font-display heading">
          {heading}
        </h2>
      </div>
      <div className="w-full mt-6 md:mt-6 md:w-2/3">
        <div className="rich-text">{copy}</div>
      </div>
    </article>
  </>
);

ThirdSection.propTypes = {
  heading: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
  copy: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  alt: PropTypes.string,
};

export { ThirdSection };
