/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const BoxIcon = (props) => (
  <svg
    fill="#152735"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 532 389"
    {...props}
  >
    <path d="M443.449 86.831l-175-84.48a5.624 5.624 0 00-4.9 0l-175 84.48a5.657 5.657 0 00-3.19 5.09v205.16a5.657 5.657 0 003.19 5.09l175 84.48a5.644 5.644 0 004.9 0l175-84.48a5.657 5.657 0 003.19-5.09V91.921a5.668 5.668 0 00-3.19-5.09zm-177.45-73.12l162 78.21-47 22.67a5.335 5.335 0 00-1-.6l-160.82-77.68 46.82-22.6zm-59.57 29l161.77 78.1-33.13 16-161.71-78.09 33.07-16.01zm165.56 88.79v59.13l-30.95 14.94v-59.13l30.95-14.94zm63.36 162l-163.7 79v-192.56l39-18.85a5.658 5.658 0 003.37-5.17c0-3.1-2.55-5.65-5.65-5.65-.916 0-1.82.223-2.63.65l-39.78 19.21-15.6-7.56a5.658 5.658 0 00-2.28-.48c-3.098 0-5.65 2.551-5.65 5.65a5.657 5.657 0 003.02 5l14.91 7.2v192.63l-163.7-79v-192.66l124.7 60.18c.81.427 1.714.65 2.63.65 3.1 0 5.65-2.55 5.65-5.65a5.658 5.658 0 00-3.37-5.17l-122.23-59 56.04-27.08 169.59 81.87.08.1v67.75c0 3.1 2.55 5.65 5.65 5.65a5.724 5.724 0 002.45-.56l42.24-20.4a5.638 5.638 0 003.19-5.08v-68.12l52.08-25.14v192.63l-.01-.04z" />
    <path d="M143.239 271.641l-25.68-12.39a5.64 5.64 0 00-2.455-.562c-3.095 0-5.642 2.547-5.642 5.642a5.649 5.649 0 003.187 5.08l25.71 12.4c.718.316 1.494.48 2.279.48 3.099 0 5.65-2.55 5.65-5.65a5.657 5.657 0 00-3.02-5h-.03zm23.64-15.38l-49.28-23.79a5.658 5.658 0 00-2.28-.48c-3.098 0-5.65 2.551-5.65 5.65a5.657 5.657 0 003.02 5l49.28 23.79c.718.316 1.494.48 2.279.48 3.099 0 5.65-2.55 5.65-5.65a5.657 5.657 0 00-3.02-5z" />
  </svg>
);

export default BoxIcon;
