import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import Hand from '../icons/hand';
import House from '../icons/house';

const Sydney = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted } = data.site.siteMetadata;

  return (
    <article
      className={`max-w-6xl px-6 pt-12 pb-16 mx-auto mt-20 ${'text-white bg-brand-blue'}`}
    >
      <div className="w-24 h-1 mx-auto bg-brand-orange" />
      <h2 className="mt-8 text-5xl font-semibold leading-none text-center uppercase font-display">
        <span className="text-brand-orange">Efficient and Affordable</span>{' '}
        Removalist in Sydney
      </h2>
      <div className="w-full mt-12">
        <div className="flex justify-center w-full mt-6 text-center md:mt-0">
          <div className="max-w-4xl rich-text">
            Our goal at Frontline Removals is to provide you with efficient
            moving services to make your moving a breeze. Our experienced
            removal experts are well-equipped to make sure that all your
            belongings are securely packed and handled with utmost care while in
            transit.
            <br />
            <br />
            Are you moving interstate? We got you covered. We have years of
            experience moving things across Australia. Whether you’ll be
            travelling by car or plane, trust us to deliver your stuff even if
            it’s to another state hundreds of miles away.
            <br />
            <br />
            At Frontline Removals, we only use high-quality materials for
            packing designed to keep your belongings safe and damage-free. We
            make sure that your items are perfectly packed and arrive at your
            new place unscathed.
            <br />
            <br />
            We can help you move home with the least stress and at the best
            value.
            <div className="flex justify-center mt-12 -mb-6">
              <House className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h2>Customised storage options</h2>
            <br />
            Part of relocating is finding suitable storage for your belongings
            while waiting to move in. Frontline’s moving experts can help you
            decide which storage solution would be perfect for your unique
            needs.
            <br />
            <br />
            In case you are relocating to a smaller place, downsizing your
            business, or if you’re moving abroad, Frontline has an excellent
            safekeeping solution for you. Whether you need storage in the short
            term or for an extended period, we got you covered.
            <h2>Budget-friendly storage solutions</h2>
            <br />
            Frontline offers flexible storage solutions that would fit right
            into your budget. Our storage facilities adhere to the highest
            quality standard when it comes to cleanliness, weather-proofing and
            even pest control.
            <h2>Frontline's Portable Shipping Containers</h2>
            <br />
            Need storage for things that have accumulated over the years?
            Downsizing your business and need extra space to store your office
            equipment? You may opt for Frontline’s self-storage shipping
            container to keep your things secure while kept inside our storage
            facilities.
            <h3>How it works:</h3>
            <br />
            1. We will deliver your own shipping container right to your
            address. The sizes of these containers vary from 10’, 20’, 40’ that
            you can choose from according to your storage needs
            <br />
            <br />
            2. You can either load it by yourself or have us do it for you.
            <br />
            <br />
            3. We can then pick up your container afterwards to store it in our
            facility.
            <div className="flex justify-center mt-12 -mb-6">
              <Hand className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h2>Professional services</h2>
            <br />
            Need a stress-free removals service in Sydney within your budget?
            get a quote online, or speak to our removalist coordinator in Sydney
            on <a href={`tel:${phone}`}>{phoneFormatted}</a> today.
          </div>
        </div>
      </div>
    </article>
  );
};

export { Sydney };
