/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

const HouseIcon = (props) => (
  <svg
    fill="#f15c2f"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 345 343"
    {...props}
  >
    <path d="M339.41,98.74l-164-96.16a5.63,5.63,0,0,0-5.59-.07L5.73,93A5.64,5.64,0,0,0,2.8,98V335.54a5.65,5.65,0,0,0,5.66,5.66H36.74a5.66,5.66,0,0,0,5.66-5.66V182.81H302.6V335.54a5.65,5.65,0,0,0,5.66,5.66h28.28a5.65,5.65,0,0,0,5.66-5.66V103.62A5.65,5.65,0,0,0,339.41,98.74ZM302.6,148.87H42.4V137.56H302.6ZM42.4,171.5V160.19H302.6V171.5ZM330.89,329.88h-17v-198a5.65,5.65,0,0,0-5.66-5.65H36.74a5.66,5.66,0,0,0-5.66,5.65v198h-17V101.3L172.42,14l158.47,92.9Z" />
    <path d="M285.63,194.13H217.75a5.64,5.64,0,0,0-5.65,5.65V262H149.87a5.65,5.65,0,0,0-5.65,5.66v67.88a5.65,5.65,0,0,0,5.65,5.66H285.63a5.65,5.65,0,0,0,5.66-5.66V199.78A5.65,5.65,0,0,0,285.63,194.13ZM246,205.44h11.31v11.31H246Zm-22.63,0h11.31v17a5.65,5.65,0,0,0,5.66,5.65H263a5.64,5.64,0,0,0,5.65-5.65v-17H280V262H223.41ZM246,273.32h11.31v11.31H246Zm-67.88,0h11.31v11.31H178.16Zm33.94,56.56H155.53V273.32h11.31v17a5.65,5.65,0,0,0,5.66,5.65h22.63a5.64,5.64,0,0,0,5.65-5.65v-17H212.1Zm67.88,0H223.41V273.32h11.31v17a5.65,5.65,0,0,0,5.66,5.65H263a5.64,5.64,0,0,0,5.65-5.65v-17H280Z" />
    <path d="M138.56,86.65v22.62a5.66,5.66,0,0,0,5.66,5.66h56.56a5.65,5.65,0,0,0,5.66-5.66V86.65A5.66,5.66,0,0,0,200.78,81H144.22A5.67,5.67,0,0,0,138.56,86.65Zm11.31,5.66h45.26v11.31H149.87Z" />
  </svg>
);

export default HouseIcon;
