import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';

import House from '../icons/house';

const Kempsey = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          phone
          phoneFormatted
          address
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted } = data.site.siteMetadata;

  return (
    <article
      className={`max-w-6xl px-6 pt-12 pb-16 mx-auto mt-20 ${'text-white bg-brand-blue'}`}
    >
      <div className="w-24 h-1 mx-auto bg-brand-orange" />
      <h2 className="mt-8 text-5xl font-semibold leading-none text-center uppercase font-display">
        Tailor-Made{' '}
        <span className="text-brand-orange">Removalists in Kempsey</span>
      </h2>
      <div className="w-full mt-12">
        <div className="flex justify-center w-full mt-6 text-center md:mt-0">
          <div className="max-w-4xl rich-text">
            We understand that every client’s need is different – and that is
            why we ensure that all of your individual needs will be catered for.
            Our team will compile a detailed relocation plan that will be
            uniquely tailored to your exact requirements.
            <br />
            <br /> Be it huge electrical equipment, machinary, art pieces, fine
            china, glass and other fragile items, our team knows how to get
            things packed the right way. We will carefully label your effects so
            that it is easy to unpack upon delivery to your new premises.
            <br />
            <br />
            <div className="flex justify-center mt-12">
              <House className={`w-24 ${'text-brand-orange'}`} />
            </div>
            <h2>
              <Link to="https://www.frontlineremovals.com.au/">
                Frontline Removals
              </Link>
              ’ storage solutions
            </h2>
            <br />
            There are times when you do not want to carry all your items to your
            new space. Then, how do you securely store your business equipment
            or personal possessions for a period of time? This is where our
            complete secure storage solution comes handy.
            <br />
            <br />
            We provide secure storage solutions for all of your residential or
            commercial requirements. Whether you require long term or short term
            storage, our purpose-built containers will ensure the safety and
            care of all your personal or business possessions. Whatever the
            complexity or customisation involved, our promise of a prompt and
            hassle free removalist services is within the reach of your budget.
            <br />
            <br />
            Our high-tech storage facilities are well-insulated from unwanted
            elements like moisture, insects, fire and theft and are monitored
            round the clock for maximum security.
            <br />
            <br />
            Our dedicated customer care department, allows us to guarantee your
            complete satisfaction, by continuing to help you even when we are
            done with your relocation.
            <br />
            <br /> Need to know more about your removalist partner in Kempsey?
            Call us on <a href={`tel:${phone}`}>{phoneFormatted}</a> and let our
            expert removalist services in Kempsey help you prepare for a smooth
            and successful move.
          </div>
        </div>
      </div>
    </article>
  );
};

export { Kempsey };
